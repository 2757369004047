import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Image } from './image';

function Masthead({
    alt, content, image, list, title, subtitle, svg, variant,
}) {
    return (
        <>
            <header className={`c-masthead b-section--bottom ${variant}`}>
                <div className="c-masthead__content-container">
                    {title && (
                        <h1 className="c-masthead__title" dangerouslySetInnerHTML={{ __html: title }} />
                    )}
                    {content && (
                        <div className="c-masthead__content" dangerouslySetInnerHTML={{ __html: content }} />
                    )}
                </div>

                <div className="c-masthead__img-container">
                    {svg && (
                        <>
                            <div>
                                <svg className="c-masthead__svg" viewBox="0 0 634 643" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.399902 643V0.399902H272.2C388.6 0.399902 477.7 28.2999 539.5 84.0999C601.9 139.3 633.1 218.5 633.1 321.7C633.1 424.9 599.8 504.4 533.2 560.2C466.6 615.4 371.8 643 248.8 643H0.399902ZM252.4 564.7C347.2 564.7 418.9 544.3 467.5 503.5C516.1 462.1 540.4 401.5 540.4 321.7C540.4 159.7 451 78.6999 272.2 78.6999H89.4999V564.7H252.4Z" fill="#0C40FD"/>
                                </svg>
                            </div>
                            <svg className="c-masthead__svg-2" viewBox="0 0 606 676" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M504.9 182.9C489.9 145.7 466.8 119 435.6 102.8C405 86.6002 361.8 78.5002 306 78.5002C242.4 78.5002 193.2 86.9002 158.4 103.7C124.2 120.5 107.1 144.5 107.1 175.7C107.1 196.7 112.2 214.1 122.4 227.9C133.2 241.1 149.7 251.9 171.9 260.3C194.7 268.7 225 275.6 262.8 281L387 299C461.4 310.4 516.3 331.7 551.7 362.9C587.7 393.5 605.7 435.5 605.7 488.9C605.7 547.7 580.5 593.6 530.1 626.6C479.7 659 409.5 675.2 319.5 675.2C219.9 675.2 143.7 658.4 90.9 624.8C38.1 591.2 7.8 540.2 0 471.8H91.8C100.2 514.4 123 545.9 160.2 566.3C197.4 586.7 250.5 596.9 319.5 596.9C381.9 596.9 429.9 587.6 463.5 569C497.1 549.8 513.9 522.8 513.9 488C513.9 458.6 502.5 435.5 479.7 418.7C456.9 401.9 419.7 389.6 368.1 381.8L256.5 365.6C196.5 356.6 149.1 344.3 114.3 328.7C80.1 313.1 55.2 293.3 39.6 269.3C24.6 244.7 17.1 214.4 17.1 178.4C17.1 122.6 42.6 79.1002 93.6 47.9002C144.6 16.1002 215.4 0.200195 306 0.200195C361.8 0.200195 408.3 6.20023 445.5 18.2003C483.3 29.6003 513.9 47.6003 537.3 72.2003C560.7 96.8003 578.4 128.6 590.4 167.6L504.9 182.9Z" fill="#0C40FD"/>
                            </svg>
                        </>
                    )}

                    {image.url && (
                        <Image className="c-masthead__img b-section--bottom" image={image} alt={alt} />
                        // <Img className="c-masthead__img b-section--bottom" fluid={image.fluid} alt={alt} />
                    )}

                    {list && (
                        <div className="c-masthead__secondary-content-container">
                            {subtitle && (
                                <h2 className="c-masthead__subtitle">{subtitle}</h2>
                            )}
                            {list[0] && list[0].skill && (
                                <ul className="c-masthead__list">
                                    {list.map(({ skill }) => (
                                        <li className="c-masthead__item" key={skill.id}>{skill.document[0].data.name}</li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    )}
                </div>
            </header>
        </>
    );
}

Masthead.defaultProps = {
    alt: '',
    image: {},
    list: [],
    subtitle: '',
    svg: false,
    variant: '',
};

Masthead.propTypes = {
    alt: PropTypes.string,
    content: PropTypes.string.isRequired,
    image: PropTypes.object,
    list: PropTypes.array,
    subtitle: PropTypes.string,
    svg: PropTypes.bool,
    title: PropTypes.string.isRequired,
    variant: PropTypes.string,
};

export default Masthead;
