import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import TransitionLink from 'gatsby-plugin-transition-link';
import { Image } from './image';

function WorkItem({
    alt, client, colour, image, title, url, video,
}) {
    return (
        <>
            <article className="c-work-item">
                <TransitionLink
                    className="c-work-item__link"
                    to={url}
                    exit={{
                        trigger: ({
                            node, e,
                        }) => {
                            const link = e.target;
                            const nav = node.querySelector('.c-nav');
                            const page = node.querySelector('.b-page');
                            const pageOffset = -(Math.round(window.scrollY - nav.offsetHeight));
        
                            // Set the current page to fixed and set top position to
                            // match scroll position, also hide the clicked link
                            page.classList.add('is-exiting-opacity');
                            page.style.top = `${pageOffset}px`;
                        },
                        length: 0.45,
                    }}
                    entry={{
                        trigger: ({
                            node, e,
                        }) => {
                            const html = document.documentElement;
                            const link = e.target;
                            const page = node.querySelector('.b-page');
                            const oldPage = document.querySelector('.b-page.is-exiting-opacity');
        
                            // Hide the clicked link
                            link.style.opacity = 0;
        
                            // Being the animation from work item to masthead
                            html.style.overflow = 'hidden';
                            page.classList.add('is-entering-opacity');
        
                            page.addEventListener('animationend', () => {
                                html.style.overflow = '';
                                page.classList.remove('is-entering-opacity');
                            });
                        },
                        delay: 0.45,
                        length: 0.45,
                    }}
                >
                    <div className="c-work-item__media">
                        {image && (
                            <Image className="c-work-item__img" image={image} alt={alt} style={{ backgroundColor: colour ? colour : 'none' }} />
                            // <Img className="c-work-item__img" fluid={image.url} alt={alt} />
                        )}
                        {video && (
                            <video autoPlay={true} className="c-work-item__video" loop={true} muted={true} playsInline={true} src={video} />
                        )}
                    </div>
                    <div className="c-work-item__content">
                        {title && (
                            <h2 className="c-work-item__title">{title}</h2>
                        )}
                        {client && (
                            <span className="c-work-item__subtitle">{client}</span>
                        )}
                    </div>
                </TransitionLink>
            </article>
        </>
    );
}

WorkItem.defaultProps = {
    alt: '',
    colour: '',
};

WorkItem.propTypes = {
    alt: PropTypes.string,
    client: PropTypes.string.isRequired,
    colour: PropTypes.string,
    image: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default WorkItem;
