import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

function globalData() {
    const data = useStaticQuery(graphql`
        query siteFooter {
            allPrismicGlobal {
                edges {
                    node {
                        lang
                        data {
                            title {
                                text
                            }
                            content {
                                html
                            }
                            social {
                                type
                                url {
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    return data;
}

function Footer({
    lang
}) {
    const data = globalData();
    let footer;

    data.allPrismicGlobal.edges.map(({ node }) => (
        node.lang === lang && (
            footer = node.data
        )
    ));

    return (
        <>
            <footer className="c-footer">
                <svg className="c-footer__shape" viewBox="0 0 1920 160" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#0C40FD" d="M-101.043 167 2144.851.055l88.994 1197.227L-12.05 1364.227z"/>
                </svg>
                <div className="c-footer__wrap b-wrap">
                    <div className="c-footer__section b-section">
                        <div className="c-footer__title-container">
                            <h2 className="c-footer__title">{footer.title.text}</h2>
                        </div>

                        <div className="c-footer__content-container">
                            <div className="c-footer__content" dangerouslySetInnerHTML={{ __html: footer.content.html }}></div>

                            {footer.social[0].url && (
                                <ul className="c-footer__social-list">
                                    {footer.social && footer.social.map(({ type, url }, index) => (
                                        <li className="c-footer__social-item" key={index}>
                                            <a className="c-footer__social-link" href={url.url} rel="noopener noreferrer" target="_blank">
                                                {type === 'Instagram' && (
                                                    <svg className="c-icon" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                        <title>{type}</title>
                                                        <path d="M8 0c2.173 0 2.445.009 3.298.048.852.039 1.433.174 1.942.372.526.204.972.478 1.417.923.445.445.718.891.923 1.417.198.509.333 1.09.372 1.942.039.853.048 1.126.048 3.298 0 2.173-.009 2.445-.048 3.298-.039.852-.174 1.433-.372 1.942-.204.526-.478.972-.923 1.417-.445.445-.891.718-1.417.923-.509.198-1.09.333-1.942.372-.853.039-1.126.048-3.298.048-2.173 0-2.445-.009-3.298-.048-.852-.039-1.433-.174-1.942-.372-.526-.204-.972-.478-1.417-.923-.445-.445-.718-.891-.923-1.417-.198-.509-.333-1.09-.372-1.942-.039-.853-.048-1.126-.048-3.298 0-2.173.009-2.445.048-3.298.039-.852.174-1.433.372-1.942.204-.526.478-.972.923-1.417.445-.445.891-.718 1.417-.923.509-.198 1.09-.333 1.942-.372.853-.039 1.126-.048 3.298-.048zm0 1.441c-2.136 0-2.389.008-3.233.047-.78.036-1.204.166-1.485.275-.373.145-.64.318-.92.598-.28.28-.453.546-.598.92-.11.282-.24.705-.275 1.485-.038.844-.047 1.097-.047 3.233s.008 2.389.047 3.233c.036.78.166 1.204.275 1.485.145.373.319.64.598.92.28.28.546.453.92.598.282.11.705.24 1.485.275.843.038 1.096.047 3.233.047 2.136 0 2.389-.008 3.233-.047.78-.036 1.204-.166 1.485-.275.373-.145.64-.318.92-.598.28-.28.453-.546.598-.92.11-.282.24-.705.275-1.485.038-.844.047-1.097.047-3.233s-.008-2.389-.047-3.233c-.036-.78-.166-1.204-.275-1.485-.145-.373-.318-.64-.598-.92-.28-.28-.546-.453-.92-.598-.282-.11-.705-.24-1.485-.275-.844-.038-1.097-.047-3.233-.047zm0 2.45c2.269 0 4.108 1.839 4.108 4.108 0 2.269-1.839 4.108-4.108 4.108-2.269 0-4.108-1.839-4.108-4.108 0-2.269 1.839-4.108 4.108-4.108zm0 6.775c1.473 0 2.667-1.194 2.667-2.667 0-1.473-1.194-2.667-2.667-2.667-1.473 0-2.667 1.194-2.667 2.667 0 1.473 1.194 2.667 2.667 2.667zm5.23-6.937c0 .53-.43.96-.96.96s-.96-.43-.96-.96.43-.96.96-.96.96.43.96.96z"></path>
                                                    </svg>
                                                )}

                                                {type === 'Twitter' && (
                                                    <svg className="c-icon" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                        <title>{type}</title>
                                                        <path d="M16 3.078c-.588.268-1.221.449-1.885.53.678-.416 1.198-1.076 1.443-1.862-.635.385-1.336.665-2.086.816-.597-.654-1.451-1.062-2.396-1.062-1.812 0-3.281 1.507-3.281 3.366 0 .264.028.52.085.767-2.728-.141-5.146-1.479-6.766-3.518-.283.499-.444 1.078-.444 1.694 0 1.167.579 2.198 1.46 2.801-.537-.016-1.044-.169-1.488-.42v.041c0 1.631 1.132 2.992 2.634 3.3-.275.079-.565.118-.865.118-.211 0-.418-.02-.618-.06.418 1.337 1.629 2.31 3.066 2.336-1.123.903-2.539 1.442-4.077 1.442-.265 0-.527-.015-.783-.046 1.453.953 3.179 1.511 5.032 1.511 6.039 0 9.34-5.128 9.34-9.577l-.008-.436c.641-.475 1.198-1.067 1.637-1.742"></path>
                                                    </svg>
                                                )}

                                                {type === 'LinkedIn' && (
                                                    <svg className="c-icon" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                        <title>{type}</title>
                                                        <path d="M16 15.271H12.4638V10.1577C12.4638 8.81933 11.9106 7.90564 10.6943 7.90564C9.76388 7.90564 9.24647 8.52222 9.00564 9.11644C8.91533 9.32973 8.92945 9.62684 8.92945 9.92395V15.271H5.42615C5.42615 15.271 5.47131 6.21331 5.42615 5.38997H8.92945V6.94072C9.13641 6.26267 10.2559 5.29496 12.0423 5.29496C14.2587 5.29496 16 6.71626 16 9.77679V15.271ZM1.88335 4.15402H1.86077C0.731891 4.15402 0 3.39866 0 2.4412C0 1.46511 0.753528 0.725586 1.90499 0.725586C3.0555 0.725586 3.76294 1.46324 3.78551 2.4384C3.78551 3.39587 3.0555 4.15402 1.88335 4.15402ZM0.403575 5.38997H3.52211V15.271H0.403575V5.38997Z"></path>
                                                    </svg>
                                                )}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            )}

                            <p className="c-footer__build">Build by <a href="https://www.robsimpson.digital" target="_blank">Rob Simpson</a></p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
