import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import 'scss/main.scss';
import { SEO } from 'components';
import Nav from '../components/nav';
import Masthead from '../components/masthead';
import WorkItem from '../components/work-item';
import Footer from '../components/footer';

function IndexPage({ data: { prismicHome }, location }) {
    const { data, lang } = prismicHome;
    const langUrl = (lang === 'en-gb') ? '' : `${lang.substring(0, lang.indexOf('-'))}/`;

    return (
        <>
            <SEO lang={lang} pathname={location.pathname} title=" " />
            <Nav lang={lang} />
            <div className="b-page">
                <div className="b-wrap">
                    <Masthead
                        variant="c-masthead--homepage"
                        title={data.title.text}
                        content={data.description}
                        svg={true}
                    />

                    <div className="b-page__wrap">
                        <div className="p-work-listing b-section">
                            {data.work && data.work.length > 0 && data.work.map(({ article }) => {
                                if (article) {
                                    return (
                                        <WorkItem
                                            key={article.id}
                                            image={article.document[0].data.image}
                                            alt={article.document[0].data.image.alt}
                                            title={article.document[0].data.title.text}
                                            client={article.document[0].data.client}
                                            url={`/${langUrl}${article.document[0].data.client.replace(/\s+/g, '-').toLowerCase()}`}
                                            colour={article.document[0].data.transition_colour}
                                            video={article.document[0].data.video.url}
                                        />
                                    )
                                }
                            })}
                        </div>
                    </div>
                </div>

                <Footer lang={lang} />
            </div>
        </>
    );
}

IndexPage.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
};

export const query = graphql`
    query($id: String) {
        prismicHome(id: { eq: $id }) {
            lang
            uid
            data {
                title {
                    text
                }
                work {
                    article {
                        id
                        url
                        document {
                            ... on PrismicWorkArticle {
                                data {
                                    title {
                                        text
                                    }
                                    client
                                    transition_colour
                                    image {
                                        url
                                        alt
                                        dimensions {
                                            height
                                            width
                                        }
                                    }
                                    video {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default IndexPage;
